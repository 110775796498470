import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useGalleryContext} from "context/GalleryContext";
import GalleryTable from "pages/gallery/components/GalleryTable";
import GalleryFormModal from "pages/gallery/components/GalleryFormModal";

function GalleryPage(props) {

    const {permission, modal, handleModal} = useGalleryContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <GalleryTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <GalleryFormModal/>}
                </>
            )}
        </Page>
    );
}

export default GalleryPage;
