const TeamApi = {
    postCreate: '/crud/teams',
    postAction: '/crud/teams/:id/action',
    postOrder: '/crud/teams/:id/order',

    putUpdate: '/crud/teams/:id',

    deleteDestroy: '/crud/teams/:id',

    getIndex: '/crud/teams',
    getSelect: '/crud/teams/select',
    getId: '/crud/teams/:id',
}

export default TeamApi;
