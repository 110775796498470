import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import FaqApi from "api/faq.api";
import {serviceFaqDestroy, serviceFaqOrder, serviceFaqSave, serviceFaqUpdateAction} from "services/faq.service";

const FaqContext = createContext();

export const FaqProvider = ({children}) => {

    const translateKey = 'crm.Faq';
    const permission = 'faq';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false
    });

    const {dataSource, setDataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(FaqApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await serviceFaqUpdateAction(id, action);
        if (res) await fetchDataSource();
    }

    const handleDelete = async (id) => {
        const res = await serviceFaqDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await serviceFaqSave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const handleOrderChange = async (value, id) => {
        const index = dataSource.data.findIndex(i => i.id === id);
        dataSource.data[index].order = value;
        setDataSource(d => ({
            ...d,
            data: [...dataSource.data]
        }));
    }

    const handleOrderSave = async (value, id) => {
        if (value && id) {
            const res = await serviceFaqOrder(id, {order: value});
            if (res) {
                await fetchDataSource()
            }
        }
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave,
        handleOrderChange,
        handleOrderSave
    };

    return <FaqContext.Provider value={values}>
        {children}
    </FaqContext.Provider>
}

export const useFaqContext = () => useContext(FaqContext);
