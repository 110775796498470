import Api from "api/translate.api";
import {API} from "utils/api";

export const serviceTranslateSave = async (data) => {
    try {
        const res = await API.post(Api.change, data)
        return !!res;
    } catch (e) {
        return false
    }
}
