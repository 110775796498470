import React from 'react';
import LanguagePage from "pages/language/LanguagePage";
import {LanguageProvider} from "context/LanguageContext";

function LanguagePageProvider(props) {
    return (
        <LanguageProvider>
            <LanguagePage/>
        </LanguageProvider>
    );
}

export default LanguagePageProvider;
