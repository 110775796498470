import {
    AiOutlineQuestionCircle,
    AiOutlineTeam,
    BsReverseLayoutTextWindowReverse,
    CgReadme,
    FaRegCommentDots,
    FiBox,
    FiCode,
    FiHome,
    FiKey,
    FiLayout,
    FiSettings,
    FiUsers,
    IoLanguage,
    MdGTranslate, MdMeetingRoom, MdOutlineContacts,
    MdOutlineDesignServices, MdOutlineWidgets, MdRule, TfiGallery, TfiLayoutSlider
} from "react-icons/all";
import {FiCalendar} from "@react-icons/all-files/fi/FiCalendar";

const translateKey = 'crm.Sidebar.';

function BiLogoCodepen() {
    return null;
}

export default [
    {
        title: translateKey + 'HomePage',
        icon: <FiHome/>,
        route: 'app.index'
    },
    {
        title: translateKey + 'Schedule',
        icon: <FiCalendar/>,
        route: 'app.schedule'
    },
    {
        title: translateKey + 'Rooms',
        icon: <MdMeetingRoom/>,
        route: 'app.room'
    },
    {
        title: translateKey + 'Services',
        icon: <MdRule/>,
        route: 'app.service'
    },
    {
        title: translateKey + 'Blogs',
        icon: <CgReadme/>,
        route: 'app.blog'
    },
    {
        title: translateKey + 'Teams',
        icon: <AiOutlineTeam/>,
        route: 'app.team'
    },
    {
        title: translateKey + 'Control',
        icon: <FiLayout/>,
        route: 'app.control',
        children: [
            {
                title: translateKey + 'Menus',
                icon: <BsReverseLayoutTextWindowReverse/>,
                route: 'app.menu'
            },
            {
                title: translateKey + 'Users',
                icon: <FiUsers/>,
                route: 'app.user'
            },
            {
                title: translateKey + 'Sliders',
                icon: <TfiLayoutSlider/>,
                route: 'app.slider'
            },
            {
                title: translateKey + 'Gallery',
                icon: <TfiGallery/>,
                route: 'app.gallery'
            },
            {
                title: translateKey + 'Contacts',
                icon: <MdOutlineContacts/>,
                route: 'app.contact'
            },
            {
                title: translateKey + 'Widgets',
                icon: <MdOutlineWidgets/>,
                route: 'app.widget'
            },
        ]
    },
    {
        title: translateKey + 'Setting',
        icon: <FiSettings/>,
        route: 'app.otherSetting',
        children: [
            {
                title: translateKey + 'Languages',
                icon: <IoLanguage/>,
                route: 'app.language',
                parent: 'app.otherSetting'
            },
            {
                title: translateKey + 'Translates',
                icon: <MdGTranslate/>,
                route: 'app.translate',
                parent: 'app.otherSetting'
            },
            {
                title: translateKey + 'Permissions',
                icon: <FiKey/>,
                route: 'app.permission',
                parent: 'app.otherSetting'
            },
            {
                title: translateKey + 'SeoMetaTag',
                icon: <FiCode/>,
                route: 'app.seo',
                parent: 'app.otherSetting'
            },
        ]
    }
]
