import React from 'react';
import {Page} from "components/ui";
import {can} from "utils/helpers";
import {useWidgetContext} from "context/WidgetContext";
import WidgetTable from "pages/widget/components/WidgetTable";
import WidgetFormModal from "pages/widget/components/WidgetFormModal";
import WidgetFilter from "pages/widget/components/WidgetFilter";

function WidgetPage(props) {

    const {permission, modal} = useWidgetContext();

    return (
        <Page>
            <WidgetFilter/>
            <WidgetTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <WidgetFormModal/>}
                </>
            )}
        </Page>
    );
}

export default WidgetPage;
