import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useRoomContext} from "context/RoomContext";
import RoomTable from "pages/room/components/RoomTable";
import RoomFormModal from "pages/room/components/RoomFormModal";
import RoomFilter from "pages/room/components/RoomFilter";

function RoomPage(props) {

    const {permission, modal, handleModal} = useRoomContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <RoomFilter/>
            <RoomTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <RoomFormModal/>}
                </>
            )}
        </Page>
    );
}

export default RoomPage;
