import React, {useEffect, useState} from 'react';
import {Button, Card, FormGroup, Modal} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormColorInput, FormInput, FormPhoto, FormSelect, FormTextarea} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useSliderContext} from "context/SliderContext";
import {useForm} from "hooks/useForm";

function SliderFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useSliderContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [preview, setPreview] = useState('');

    const positions = [
        {id: 'topLeft', name: 'Top left'},
        {id: 'topRight', name: 'Top right'},
        {id: 'topCenter', name: 'Top center'},
        {id: 'centerLeft', name: 'Center left'},
        {id: 'center', name: 'Center'},
        {id: 'centerRight', name: 'Center right'},
        {id: 'bottomLeft', name: 'Bottom left'},
        {id: 'bottomCenter', name: 'Bottom center'},
        {id: 'bottomRight', name: 'Bottom right'},
    ]

    const handleForm = async (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                name: item.translates && item.translates[i.code] ? item.translates[i.code].name : '',
                description: item.translates && item.translates[i.code] ? item.translates[i.code].description : '',
                button_text: item.translates && item.translates[i.code] ? item.translates[i.code].button_text : '',
                button_link: item.translates && item.translates[i.code] ? item.translates[i.code].button_link : ''
            }
        })
        const customForm = {
            id: item.id || '',
            photo: '',
            options: item?.options || {
                name_position: 'topLeft',
                name_color: '#000',
                description_position: 'topLeft',
                description_color: '#000',
                button_position: 'topLeft',
                button_color: '#000',
                button_bg_color: '#555',
            },
            translates,
        };
        if (item?.photo?.original) {
            setPreview(item.photo.original)
        }
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Sliders')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="lg:!w-[960px] !w-full"
        >
            {(ready && values.translates) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={9}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Photo')}
                                error="photo"
                            >
                                <FormPhoto
                                    height={150}
                                    preview={preview}
                                    value={values.photo}
                                    onChange={e => setField('photo', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={15}>
                            <Row gutter={[16, 33]}>
                                <Col xs={24} lg={12}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.NamePosition')}
                                        error="options.name_position"
                                    >
                                        <FormSelect
                                            allowClear={false}
                                            showSearch={false}
                                            options={positions}
                                            value={values.options.name_position}
                                            onChange={e => setField('options', {...values.options, name_position: e})}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.NameColor')}
                                        error="options.name_color"
                                    >
                                        <FormColorInput
                                            value={values.options.name_color}
                                            onChange={e => setField('options', {...values.options, name_color: e})}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.DescriptionPosition')}
                                        error="options.description_position"
                                    >
                                        <FormSelect
                                            options={positions}
                                            value={values.options.description_position}
                                            onChange={e => setField('options', {...values.options, description_position: e})}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.DescriptionColor')}
                                        error="options.description_color"
                                    >
                                        <FormColorInput
                                            value={values.options.description_color}
                                            onChange={e => setField('options', {...values.options, description_color: e})}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs={24} lg={8}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.ButtonPosition')}
                                    >
                                        <FormSelect
                                            options={positions}
                                            value={values.options.button_position}
                                            onChange={e => setField('options', {...values.options, button_position: e})}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs={24} lg={8}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.ButtonColor')}
                                    >
                                        <FormColorInput
                                            value={values.options.button_color}
                                            onChange={e => setField('options', {...values.options, button_color: e})}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs={24} lg={8}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.ButtonBgColor')}
                                    >
                                        <FormColorInput
                                            value={values.options.button_bg_color}
                                            onChange={e => setField('options', {...values.options, button_bg_color: e})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>


                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Card title={i.name} key={index}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Name')}
                                                error={`translates.${i.code}.name`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.name}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], name: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Description')}
                                                error={`translates.${i.code}.description`}
                                            >
                                                <FormTextarea
                                                    rows={3}
                                                    value={values?.translates[i.code]?.description}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], description: e}})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.ButtonText')}
                                            >
                                                <FormInput
                                                    rows={3}
                                                    value={values?.translates[i.code]?.button_text}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], button_text: e}})}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={24} lg={12}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.ButtonLink')}
                                            >
                                                <FormInput
                                                    rows={3}
                                                    value={values?.translates[i.code]?.button_link}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], button_link: e}})}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default SliderFormModal;
