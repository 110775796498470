import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useSeoContext} from "context/SeoContext";
import SeoTable from "pages/seo/components/SeoTable";
import SeoFormModal from "pages/seo/components/SeoFormModal";
import SeoFilter from "pages/seo/components/SeoFilter";
import SeoRobotFormModal from "pages/seo/components/SeoRobotFormModal";

function SeoPage(props) {

    const {permission, modal, handleModal} = useSeoContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <div className="space-x-2 flex items-center">
                            <button className="btn btn--indigo" onClick={() => handleModal('robot', true)}>
                                Robots.txt
                            </button>
                            <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                                {translate('button.Add')}
                            </button>
                        </div>
                    )}
                </>
            }
        >
            <SeoFilter/>
            <SeoTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <SeoFormModal/>}
                    {modal.robot && <SeoRobotFormModal/>}
                </>
            )}
        </Page>
    );
}

export default SeoPage;
