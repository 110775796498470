const TestimonialApi = {
    postCreate: '/crud/testimonials',
    postAction: '/crud/testimonials/:id/action',
    postOrder: '/crud/testimonials/:id/order',

    putUpdate: '/crud/testimonials/:id',

    deleteDestroy: '/crud/testimonials/:id',

    getIndex: '/crud/testimonials',
    getSelect: '/crud/testimonials/select',
    getId: '/crud/testimonials/:id',
}

export default TestimonialApi;
