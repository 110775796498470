import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import LanguageApi from "api/language.api";
import {serviceLanguageDestroy, serviceLanguageSave, serviceLanguageUpdateAction} from "services/language.service";
import {serviceAppFetchStart} from "services/app.service";

const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {

    const translateKey = 'crm.Language';
    const permission = 'language';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(LanguageApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await serviceLanguageUpdateAction(id, action);
        if (res) {
            await serviceAppFetchStart();
            await fetchDataSource();
        }
    }

    const handleDelete = async (id) => {
        const res = await serviceLanguageDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await serviceLanguageSave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave
    };

    return <LanguageContext.Provider value={values}>
        {children}
    </LanguageContext.Provider>
}

export const useLanguageContext = () => useContext(LanguageContext);
