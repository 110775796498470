import React from 'react';
import MenuPage from "pages/menu/MenuPage";
import {MenuProvider} from "context/MenuContext";

function MenuPageProvider(props) {
    return (
        <MenuProvider>
            <MenuPage/>
        </MenuProvider>
    );
}

export default MenuPageProvider;
