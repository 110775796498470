import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {usePermissionContext} from "context/PermissionContext";
import {useForm} from "hooks/useForm";

function PermissionFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = usePermissionContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                name: item.translates && item.translates[i.code] ? item.translates[i.code].name : ''
            }
        })
        const customForm = {
            id: item.id || '',
            translates,
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Permissions')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="lg:!w-96 !w-full"
        >
            {(ready && values.translates) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} lg={24}>
                                        <FormGroup
                                            label={translate(translateKey + '.Label.Name') + ' (' + i.name + ')'}
                                            error={`translates.${i.code}.name`}
                                        >
                                            <FormInput
                                                value={values?.translates[i.code]?.name}
                                                onChange={e => setField('translates', {
                                                    ...values.translates,
                                                    [i.code]: {name: e}
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default PermissionFormModal;
