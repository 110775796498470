import React from 'react';
import FaqPage from "pages/faq/FaqPage";
import {FaqProvider} from "context/FaqContext";

function FaqPageProvider(props) {
    return (
        <FaqProvider>
            <FaqPage/>
        </FaqProvider>
    );
}

export default FaqPageProvider;
