import React from 'react';
import GalleryPage from "pages/gallery/GalleryPage";
import {GalleryProvider} from "context/GalleryContext";

function GalleryPageProvider(props) {
    return (
        <GalleryProvider>
            <GalleryPage/>
        </GalleryProvider>
    );
}

export default GalleryPageProvider;
