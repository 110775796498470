const SliderApi = {
    postCreate: '/crud/sliders',
    postAction: '/crud/sliders/:id/action',
    postOrder: '/crud/sliders/:id/order',

    putUpdate: '/crud/sliders/:id',

    deleteDestroy: '/crud/sliders/:id',

    getIndex: '/crud/sliders',
    getSelect: '/crud/sliders/select',
    getId: '/crud/sliders/:id',
}

export default SliderApi;
