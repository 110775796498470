import React, {useEffect, useState} from 'react';
import {Button, Card, FormGroup, Modal} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useLanguageContext} from "context/LanguageContext";
import {useForm} from "hooks/useForm";

function LanguageFormModal(props) {

    const {modal, handleModal, tableRow, translateKey, handleSave} = useLanguageContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        const customForm = {
            id: item.id || '',
            name: item?.name || '',
            code: item?.code || '',
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Languages')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="lg:!w-96"
        >
            {(ready) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Name')}
                                error="name"
                            >
                                <FormInput
                                    value={values.name}
                                    onChange={e => setField('name', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Code')}
                                error="code"
                            >
                                <FormInput
                                    value={values.code}
                                    onChange={e => setField('code', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default LanguageFormModal;
