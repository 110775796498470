import React, {useEffect} from 'react';
import {useAuthState} from "store/module/auth.store";
import {translate} from "utils/helpers";
import {Button, FormGroup, Modal} from "components/ui";
import {useForm} from "hooks/useForm";
import {serviceAuthEditProfile} from "services/auth.service";
import {Col, Row} from "antd";
import {FormInput, FormPhoto} from "components/ui/form";

function HeaderProfileModal({visible, setVisible}) {
    const {user} = useAuthState();
    const translateKey = 'crm.User.Label';
    const {values, setField, setValues, setLoading, loading} = useForm({});
    const handleForm = async (item = {}) => {
        const customForm = {
            id: item?.id,
            name: item?.name,
            surname: item?.surname,
            password: '',
            photo: '',
        };
        await setValues({...customForm});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await serviceAuthEditProfile(values);
        if (res) setVisible(false);
        setLoading(false)
    }

    useEffect(() => {
        if(visible) {
            handleForm(user)
        }
    }, [user, visible])

    return (
        <Modal
            title={user.fullname}
            visible={visible}
            onClose={() => setVisible(false)}
            className="w-full lg:!w-96"
        >
            <form onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>

                    <Col xs={24} lg={10}>
                        <FormGroup
                            label={translate(translateKey + '.Photo')}
                            error="photo"
                        >
                            <FormPhoto
                                value={values?.photo}
                                preview={user?.photo}
                                onChange={e => setField('photo', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={24} lg={14}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={24}>
                                <FormGroup
                                    label={translate(translateKey + '.Name')}
                                    error="name"
                                >
                                    <FormInput
                                        value={values?.name}
                                        onChange={e => setField('name', e)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={24} lg={24}>
                                <FormGroup
                                    label={translate(translateKey + '.Surname')}
                                    error="surname"
                                >
                                    <FormInput
                                        value={values?.surname}
                                        onChange={e => setField('surname', e)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={24} lg={24}>
                                <FormGroup
                                    label={translate(translateKey + '.Password')}
                                    error="password"
                                >
                                    <FormInput
                                        value={values?.password}
                                        onChange={e => setField('password', e)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col span={24} className="!flex justify-end">
                                <Button loading={loading} type={'submit'}>
                                    {translate('button.Save')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
}

export default HeaderProfileModal;
