import Api from "api/setting.api";
import {API} from "utils/api";

export const serviceSettingSave = async (data, key) => {
    try {
        await API.put(Api.putUpdate.replace(':id', key), data)
        return true;
    }
    catch (e) {
        return false;
    }
}

export const serviceSettingItem = async (id) => {
    const res = await API.get(Api.getId.replace(':id', id))
    if (res) {
        return res;
    }
}
