import React, {useEffect} from 'react';
import {can, translate} from "utils/helpers";
import {useSeoContext} from "context/SeoContext";
import {Badge, Dropdown} from "antd";
import {IoEllipsisVerticalSharp} from "react-icons/all";
import {Table} from "components/ui";

function SeoTable(props) {

    const {
        translateKey,
        permission,
        handleModal,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleAction,
        handleDelete
    } = useSeoContext();

    const columns = [
        {
            title: translate(translateKey + '.Label.Url'),
            dataIndex: 'url'
        },
        {
            title: translate(translateKey + '.Label.Name'),
            dataIndex: 'name',
            render: (value, row) => (
                <a href={row.link} className="link" target={'_blank'}>{value}</a>
            )
        },
        {
            title: translate(translateKey + '.Label.Title'),
            dataIndex: 'title'
        },
        {
            title: translate(translateKey + '.Label.Description'),
            dataIndex: 'description'
        },
    ];

    const actionRender = (row) => {
        const menus = (
            <div className="dropdown dropdown--sm">
                <div className="dropdown-body">
                    <div className="dropdown-items">
                        {can(permission + '.update') && (
                            <button
                                onClick={() => handleModal('form', true, row)}
                                className="dropdown-item h-8"
                            >
                                {translate('button.Edit')}
                            </button>
                        )}
                        {can(permission + '.action') && (
                            <button
                                className="dropdown-item h-8"
                                onClick={() => handleAction(row.id)}
                            >
                                {row.is_active ? translate('button.DeActivate') : translate('button.Activate')}
                            </button>
                        )}
                        {can(permission + '.delete') && (
                            <button
                                className="dropdown-item h-8"
                                onClick={() => handleDelete(row.id)}
                            >
                                {translate('button.Delete')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
        return <Dropdown overlay={menus} placement={'bottomRight'}>
            <button className="btn btn--action bg-gray-100 text-gray-900 dark:bg-transparent dark:text-gray-300">
                <span>
                    <IoEllipsisVerticalSharp/>
                </span>
            </button>
        </Dropdown>
    }

    useEffect(() => {
        fetchDataSource()
    }, [query])

    return (
        <Table
            tableQuery={query}
            setTableQuery={handleQuery}
            dataSource={dataSource.data}
            total={dataSource.total}
            columns={columns}
            loading={tableLoading}
            sortingStart={1}
            actionWidth={40}
            actionButton={row => actionRender(row)}
        />
    );
}

export default SeoTable;
