import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useScheduleContext} from "context/ScheduleContext";
import ScheduleTable from "pages/schedule/components/ScheduleTable";
import ScheduleFormModal from "pages/schedule/components/ScheduleFormModal";
import ScheduleFilter from "pages/schedule/components/ScheduleFilter";

function SchedulePage(props) {

    const {permission, modal, handleModal} = useScheduleContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <ScheduleFilter/>
            <ScheduleTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <ScheduleFormModal/>}
                </>
            )}
        </Page>
    );
}

export default SchedulePage;
