import React, {useEffect} from 'react';

function DashboardPage(props) {

    return (
        <div></div>
    );
}

export default DashboardPage;
