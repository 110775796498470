import React from 'react';
import {useAuthState} from "store/module/auth.store";
import {useRoutes} from "react-router-dom";
import AppProvider from "providers/AppProvider";
import PermissionProvider from "providers/PermissionProvider";
import routers from "router/routers";

function GlobalProvider(props) {
    const {token} = useAuthState();

    const RouterComponent = () => useRoutes(routers)

    return (
        <AppProvider>
            {token && <PermissionProvider>{<RouterComponent/>}</PermissionProvider>}
            {!token && <RouterComponent/>}
        </AppProvider>
    );
}

export default GlobalProvider;
