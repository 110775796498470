import React from 'react';
import ContactPage from "pages/contact/ContactPage";
import {ContactProvider} from "context/ContactContext";

function ContactPageProvider(props) {
    return (
        <ContactProvider>
            <ContactPage/>
        </ContactProvider>
    );
}

export default ContactPageProvider;
