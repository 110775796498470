import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormDatePicker, FormHtmlEditor, FormInput, FormPhoto, FormTextarea} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {usePortfolioContext} from "context/PortfolioContext";
import {useForm} from "hooks/useForm";
import moment from "moment/moment";

function PortfolioFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = usePortfolioContext();
    const [ready, setReady] = useState(false)
    const [preview, setPreview] = useState('');

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                name: item.translates && item.translates[i.code] ? item.translates[i.code].name : '',
                description: item.translates && item.translates[i.code] ? item.translates[i.code].description : '',
                content: item.translates && item.translates[i.code] ? item.translates[i.code].content : ''
            }
        })
        const customForm = {
            id: item.id || '',
            photo: '',
            link: item?.link || '',
            translates,
        };
        if (item?.photo?.original) {
            setPreview(item.photo.original)
        }
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Portfolios')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="lg:!w-[600px] !w-full"
        >
            {(ready && values.translates) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Photo')}
                                error="photo"
                            >
                                <FormPhoto
                                    height={200}
                                    preview={preview}
                                    value={values.photo}
                                    onChange={e => setField('photo', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Link')}
                            >
                                <FormInput
                                    value={values.link}
                                    onChange={e => setField('link', e)}
                                />
                            </FormGroup>
                        </Col>


                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Card title={i.name} key={index}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Name')}
                                                error={`translates.${i.code}.name`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.name}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], name: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Description')}
                                                error={`translates.${i.code}.description`}
                                            >
                                                <FormTextarea
                                                    rows={6}
                                                    value={values?.translates[i.code]?.description}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], description: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Content')}
                                                error={`translates.${i.code}.content`}
                                            >
                                                <FormHtmlEditor
                                                    value={tableRow?.translates && tableRow?.translates[i.code]?.content}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], content: e}})}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default PortfolioFormModal;
