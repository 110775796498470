import React from 'react';
import BlogPage from "pages/blog/BlogPage";
import {BlogProvider} from "context/BlogContext";

function BlogPageProvider(props) {
    return (
        <BlogProvider>
            <BlogPage/>
        </BlogProvider>
    );
}

export default BlogPageProvider;
