import React from 'react';
import TeamPage from "pages/team/TeamPage";
import {TeamProvider} from "context/TeamContext";

function TeamPageProvider(props) {
    return (
        <TeamProvider>
            <TeamPage/>
        </TeamProvider>
    );
}

export default TeamPageProvider;
