const WidgetApi = {
    postCreate: '/crud/widgets',
    postAction: '/crud/widgets/:id/action',

    putUpdate: '/crud/widgets/:id',

    deleteDestroy: '/crud/widgets/:id',

    getIndex: '/crud/widgets',
    getSelect: '/crud/widgets/select',
    getId: '/crud/widgets/:id',
}

export default WidgetApi;