import {useEffect, useState} from "react";
import {API} from "utils/api";

export const useTable = (api, newQuery = false) => {
    const initialQuery = {
        page: 1,
        limit: 25,
    };
    const [query, setQuery] = useState(initialQuery);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState({});

    const handleQuery = (newQuery = false) => {
        if (newQuery) {
            setQuery(q => ({
                ...q,
                ...newQuery
            }))
        }
        else {
            setQuery(initialQuery)
        }
    }

    const fetchDataSource = async () => {
        setLoading(true);
        try {
            const res = await API.get(api, {params: {...query, ...newQuery}});
            if (res?.data) {
                setDataSource(res);
            }
        }
        catch (e) {
            setLoading(false);
        }
        setLoading(false);
    }

    return {
        dataSource,
        setDataSource,
        fetchDataSource,
        handleQuery,
        loading,
        query
    }
}
