import React from 'react';
import DashboardPage from "pages/dashboard/DashboardPage";
import {DashboardProvider} from "context/DashboardContext";

function DashboardPageProvider(props) {
    return (
        <DashboardProvider>
            <DashboardPage/>
        </DashboardProvider>
    );
}

export default DashboardPageProvider;
