import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput, FormPhoto} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {usePartnerContext} from "context/PartnerContext";
import {useForm} from "hooks/useForm";

function PartnerFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = usePartnerContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [preview, setPreview] = useState('');

    const handleForm = async (item = {}) => {
        const customForm = {
            id: item.id || '',
            name: item.name || '',
            link: item.link || '',
        };
        if (item?.photo?.original) {
            setPreview(item.photo.original)
        }
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Partners')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="w-full"
        >
            {(ready) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={8}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Photo')}
                                error="photo"
                            >
                                <FormPhoto
                                    preview={preview}
                                    value={values.photo}
                                    onChange={e => setField('photo', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg={16} xs={24}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} lg={24}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.Name')}
                                        error="name"
                                    >
                                        <FormInput
                                            value={values.name}
                                            onChange={e => setField('name', e)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs={24} lg={24}>
                                    <FormGroup
                                        label={translate(translateKey + '.Label.Link')}
                                    >
                                        <FormInput
                                            value={values.link}
                                            onChange={e => setField('link', e)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default PartnerFormModal;
