import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useTranslateContext} from "context/TranslateContext";
import TranslateTable from "pages/translate/components/TranslateTable";
import TranslateFormModal from "pages/translate/components/TranslateFormModal";
import TranslateFilter from "pages/translate/components/TranslateFilter";

function TranslatePage(props) {

    const {permission, modal, handleModal} = useTranslateContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <TranslateFilter/>
            <TranslateTable/>
            {(modal.form && (can(permission + '.create') || can(permission + '.update'))) && <TranslateFormModal/>}
        </Page>
    );
}

export default TranslatePage;
