import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormDatePicker, FormInput, FormSelect, FormTextarea, FormTimePicker} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useScheduleContext} from "context/ScheduleContext";
import {useForm} from "hooks/useForm";
import {useFetchRoomList} from "hooks/useFetch";
import moment from "moment";

function ScheduleFormModal(props) {

    const {modal, handleModal, tableRow, translateKey, handleSave} = useScheduleContext();
    const [ready, setReady] = useState(false);
    const [rooms, fetchRooms] = useFetchRoomList();

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        const customForm = {
            id: item.id || '',
            name: item.name || '',
            room_id: item.room_id || '',
            date: item.date ? moment(item.date) : '',
            start_time: item.start_time ? moment(item.date + ' ' + item.start_time) : '',
            end_time: item.end_time ? moment(item.date + ' ' + item.end_time) : '',
            note: item.note || '',
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(values.start_time);
        const res = await handleSave({
            ...values,
            end_time: values.end_time ? values.end_time?.format('YYYY-MM-DD HH:mm:ss') : '',
            start_time: values.start_time ? values.start_time?.format('YYYY-MM-DD HH:mm:ss') : '',
        });
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
        fetchRooms();
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Schedule')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="w-full"
        >
            {(ready) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Name')}
                                error={`name`}
                            >
                                <FormInput
                                    value={values?.name}
                                    onChange={e => setField('name', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Room')}
                                error={`room_id`}
                            >
                                <FormSelect
                                    allowClear={false}
                                    showSearch={true}
                                    options={rooms}
                                    value={values?.room_id}
                                    onChange={e => setField('room_id', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Date')}
                                error={`date`}
                            >
                                <FormDatePicker
                                    value={values?.date}
                                    onChange={e => setField('date', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.StartTime')}
                                error={`start_time`}
                            >
                                <FormTimePicker
                                    value={values?.start_time}
                                    onChange={e => setField('start_time', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.EndTime')}
                                error={`end_time`}
                            >
                                <FormTimePicker
                                    value={values?.end_time}
                                    onChange={e => setField('end_time', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Note')}
                                error={`note`}
                            >
                                <FormTextarea
                                    rows={6}
                                    value={values?.note}
                                    onChange={e => setField('note', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default ScheduleFormModal;
