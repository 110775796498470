import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput, FormTextarea} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useWidgetContext} from "context/WidgetContext";
import {useForm} from "hooks/useForm";

function WidgetFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useWidgetContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        let value = {};
        languages.filter(i => {
            value[i.code] = {
                title: item.value && item.value[i.code] ? item.value[i.code].title : '',
                sub_title: item.value && item.value[i.code] ? item.value[i.code].sub_title : '',
                content: item.value && item.value[i.code] ? item.value[i.code].content : ''
            }
        })
        const customForm = {
            id: item.id || '',
            value,
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Widgets')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="w-full"
        >
            {(ready && values.value) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Card title={i.name} key={index}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Title')}
                                            >
                                                <FormInput
                                                    value={values?.value[i.code]?.title}
                                                    onChange={e => setField('value', {...values.value, [i.code]: {...values.value[i.code], title: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.SubTitle')}
                                            >
                                                <FormInput
                                                    value={values?.value[i.code]?.sub_title}
                                                    onChange={e => setField('value', {...values.value, [i.code]: {...values.value[i.code], sub_title: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Content')}
                                            >
                                                <FormTextarea
                                                    rows={5}
                                                    value={values?.value[i.code]?.content}
                                                    onChange={e => setField('value', {...values.value, [i.code]: {...values.value[i.code], content: e}})}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default WidgetFormModal;
