import React from 'react';

function FormColorInput(
    {
        value,
        onChange
    }
) {
    return (
        <div className="form-color">
            <input type="color"
                   value={value}
                   onChange={e => onChange(e.target.value)}
            />
            <div className="form-color__text">
                {value}
            </div>
        </div>
    );
}

export default FormColorInput;
