import React from 'react';
import SettingPage from "pages/setting/SettingPage";
import {SettingProvider} from "context/SettingContext";

function SettingPageProvider(props) {
    return (
        <SettingProvider>
            <SettingPage/>
        </SettingProvider>
    );
}

export default SettingPageProvider;
