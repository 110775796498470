import Api from "api/faq.api";
import {API} from "utils/api";

export const serviceFaqUpdateAction = async (id, action = 'is_active') => {
    const res = await API.post(Api.postAction.replace(':id', id), {action});
    return !!res;
}

export const serviceFaqDestroy = async (id) => {
    const res = await API.delete(Api.deleteDestroy.replace(':id', id));
    return !!res;
}

export const serviceFaqSave = async (data) => {
    try {
        let res = '';
        if (data?.id) res = await API.put(Api.putUpdate.replace(':id', data.id), data)
        else res = await API.post(Api.postCreate, data)

        return !!res;
    } catch (e) {
        return false
    }
}

export const serviceFaqItem = async (id) => {
    const res = await API.get(Api.getId.replace(':id', id))
    if (res) return res;
    return false;
}

export const serviceFaqOrder = async (id, data) => {
    const res = await API.post(Api.postOrder.replace(':id', id), data)
    if (res) return res;
    return false;
}
