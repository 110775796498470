import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {usePortfolioContext} from "context/PortfolioContext";
import PortfolioTable from "pages/portfolio/components/PortfolioTable";
import PortfolioFormModal from "pages/portfolio/components/PortfolioFormModal";
import PortfolioFilter from "pages/portfolio/components/PortfolioFilter";

function PortfolioPage(props) {

    const {permission, modal, handleModal} = usePortfolioContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <PortfolioFilter/>
            <PortfolioTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <PortfolioFormModal/>}
                </>
            )}
        </Page>
    );
}

export default PortfolioPage;
