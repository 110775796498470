import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useBlogContext} from "context/BlogContext";
import BlogTable from "pages/blog/components/BlogTable";
import BlogFormModal from "pages/blog/components/BlogFormModal";
import BlogFilter from "pages/blog/components/BlogFilter";

function BlogPage(props) {

    const {permission, modal, handleModal} = useBlogContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <BlogFilter/>
            <BlogTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <BlogFormModal/>}
                </>
            )}
        </Page>
    );
}

export default BlogPage;
