import React, {useEffect} from 'react';
import {useSettingContext} from "context/SettingContext";
import {useForm} from "hooks/useForm";
import {serviceSettingItem, serviceSettingSave} from "services/setting.service";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {Button, Card, FormGroup} from "components/ui";
import {FormInput} from "components/ui/form";

function SettingWorkTime(props) {
    const tab = 'work-time'
    const {translateKey} = useSettingContext();
    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = (item = {}) => {
        const customForm = {
            sunday: item.sunday || '',
            weekdays: item.weekdays || '',
            weekend: item.weekend || '',
        };
        setValues({...customForm});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await serviceSettingSave(values, tab);
        setLoading(false);
    }

    const fetchByKey = async () => {
        const res = await serviceSettingItem(tab);
        handleForm(res?.value)
    }

    useEffect(() => {
        if (tab) {
            fetchByKey();
        }
    }, [tab])

    return (
        <Card className={'lg:w-[500px]'} title={translate('crm.Sidebar.WorkTime')}>
            <form onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.Weekdays')}
                        >
                            <FormInput
                                value={values?.weekdays}
                                onChange={e => setField('weekdays', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.Weekend')}
                        >
                            <FormInput
                                value={values?.weekend}
                                onChange={e => setField('weekend', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.Sunday')}
                        >
                            <FormInput
                                value={values?.sunday}
                                onChange={e => setField('sunday', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24} className="!flex justify-end">
                        <Button loading={loading} type={'submit'}>
                            {translate('button.Save')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Card>
    );
}

export default SettingWorkTime;
