import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {usePermissionContext} from "context/PermissionContext";
import PermissionTable from "pages/permission/components/PermissionTable";
import PermissionFormModal from "pages/permission/components/PermissionFormModal";
import PermissionFilter from "pages/permission/components/PermissionFilter";
import PermissionOptionModal from "pages/permission/components/PermissionOptionModal";

function PermissionPage(props) {

    const {permission, modal, handleModal} = usePermissionContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <PermissionFilter/>
            <PermissionTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <PermissionFormModal/>}
                    {modal.option && <PermissionOptionModal/>}
                </>
            )}
        </Page>
    );
}

export default PermissionPage;
