const MenuApi = {
    postCreate: '/crud/menus',
    postAction: '/crud/menus/:id/action',
    postWidgetOrder: '/crud/menus/widget/:widgetId/order',
    postWidgets: '/crud/menus/:id/widgets',
    postOrder: '/crud/menus/:id/order',

    putUpdate: '/crud/menus/:id',

    deleteDestroy: '/crud/menus/:id',
    deleteWidgetDestroy: '/crud/menus/widget/:widgetId',

    getIndex: '/crud/menus',
    getWidgets: '/crud/menus/:id/widgets',
    getSelect: '/crud/menus/select',
    getId: '/crud/menus/:id',
}

export default MenuApi;
