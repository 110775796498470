import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput, FormMultiplePhoto, FormSelect, FormYoutubeVideo} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useGalleryContext} from "context/GalleryContext";
import {useForm} from "hooks/useForm";

function GalleryFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useGalleryContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        const customForm = {
            id: '',
            type: 'photo',
            youtube_id: '',
            photos: []
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    console.log(values);

    return (
        <Modal
            title={translate('crm.Sidebar.Gallery')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="w-full"
        >
            {(ready) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Type')}
                            >
                                <FormSelect
                                    allowClear={false}
                                    showSearch={false}
                                    options={[
                                        {id: 'video', name: 'Video'},
                                        {id: 'photo', name: 'Photo'},
                                    ]}
                                    value={values.type}
                                    onChange={e => {
                                        setField('type', e);
                                        if (!e) {
                                            setField('photos', [])
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        {values.type === 'photo' && (
                            <Col xs={24} lg={24}>
                                <FormGroup
                                    label={translate(translateKey + '.Label.Photo')}
                                    noBorder={true}
                                    error="photos"
                                >
                                    <FormMultiplePhoto
                                        path="gallery"
                                        onChange={e => setField('photos', e)}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {values.type === 'video' && (
                            <Col xs={24} lg={24}>
                                <FormYoutubeVideo
                                    error="youtube_id"
                                    label={translate(translateKey + '.Label.YoutubeLink')}
                                    onChange={e => setField('youtube_id', e)}
                                />
                            </Col>
                        )}

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default GalleryFormModal;
