import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useSliderContext} from "context/SliderContext";
import SliderTable from "pages/slider/components/SliderTable";
import SliderFormModal from "pages/slider/components/SliderFormModal";
import SliderFilter from "pages/slider/components/SliderFilter";

function SliderPage(props) {

    const {permission, modal, handleModal} = useSliderContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <SliderFilter/>
            <SliderTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <SliderFormModal/>}
                </>
            )}
        </Page>
    );
}

export default SliderPage;
