import React, {useEffect, useState} from 'react';
import {onlyNumberWrite, translate} from "utils/helpers";
import {Button, FormGroup, Modal} from "components/ui";
import {useMenuContext} from "context/MenuContext";
import {FormInput} from "components/ui/form";
import {serviceMenuItem, serviceMenuWidgetDestroy, serviceMenuWidgetOrder} from "services/menu.service";
import {FiTrash} from "react-icons/all";
import MenuWidgetFormModal from "pages/menu/components/MenuWidgetFormModal";
import {Alert} from "antd";

function MenuWidgetModal(props) {

    const {modal, handleModal, translateKey, tableRow} = useMenuContext();
    const [widgets, setWidgets] = useState([]);
    const [item, setItem] = useState({});
    const [visible, setVisible] = useState(false);

    const handlerClose = () => {
        handleModal('widget', false);
    }

    const fetchItem = async () => {
        const res = await serviceMenuItem(tableRow.id);
        setItem(res);
        if (res?.widgets?.length > 0) {
            setWidgets(res.widgets);
        }
        else {
            setWidgets([])
        }
    }

    const handleWidgetOrderChange = async (value, id) => {
        const index = widgets.findIndex(i => i.id === id);
        widgets[index].order = value;
        setWidgets([...widgets]);
    }

    const handleWidgetOrderSave = async (value, id) => {
        if (value && id) {
            const res = await serviceMenuWidgetOrder(id, {order: value});
            if (res) {
                await fetchItem()
            }
        }
    }

    const handleDelete = async (id) => {
        const res = await serviceMenuWidgetDestroy(id);
        if (res) {
            await fetchItem();
        }
    }

    useEffect(() => {
        if (modal.widget && tableRow.id) {
            fetchItem();
        }
    }, [modal.widget, tableRow])

    return (
        <>
            <Modal
                title={translate('crm.Sidebar.Menus') + (tableRow.name ? ' / ' + tableRow.name : '')}
                visible={modal.widget}
                onClose={() => handlerClose()}
                className="w-full"
            >
                <div className="mb-4 flex justify-end">
                    <Button type="button" onClick={() => setVisible(true)}>
                        {translate('button.Add')}
                    </Button>
                </div>
                {widgets.length > 0 && (
                    <table className="table">
                        <thead>
                        <tr>
                            <th>{translate(translateKey + '.Label.Name')}</th>
                            <th className="w-[80px] text-center">{translate(translateKey + '.Label.Order')}</th>
                            <th className="w-[80px] text-center">{translate(translateKey + '.Label.Action')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {widgets.map((i, index) => (
                            <tr key={index}>
                                <td>{i.widget.title}</td>
                                <td>
                                    <FormGroup elementClass="text-center">
                                        <FormInput
                                            className="text-center !text-sm"
                                            value={i.order}
                                            onChange={e => onlyNumberWrite(e, val => handleWidgetOrderChange(val, i.id))}
                                            onPressEnter={e => handleWidgetOrderSave(e, i.id)}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <div className="flex justify-center items-center">
                                        <Button onClick={() => handleDelete(i.id)} icon={<FiTrash/>} className="btn--action btn--sm" property="red" type="button" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}

                {widgets.length === 0 && (
                    <Alert message={translate(translateKey + '.Label.AlertNotMenuWidget')}/>
                )}
            </Modal>

            {(visible && item.id) && <MenuWidgetFormModal
                item={item}
                visible={visible}
                setVisible={setVisible}
                fetchItem={fetchItem}
            />}
        </>
    );
}

export default MenuWidgetModal;
