import AppLayout from "layouts/AppLayout";
import AuthProvider from "providers/AuthProvider";
import {Navigate} from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import LoginPage from "pages/auth/LoginPage";
import ServicePageProvider from "pages/service/ServicePageProvider";
import LanguagePageProvider from "pages/language/LanguagePageProvider";
import UserPageProvider from "pages/user/UserPageProvider";
import MenuPageProvider from "pages/menu/MenuPageProvider";
import DashboardPageProvider from "pages/dashboard/DashboardPageProvider";
import SeoPageProvider from "pages/seo/SeoPageProvider";
import PermissionPageProvider from "pages/permission/PermissionPageProvider";
import TranslatePageProvider from "pages/translate/TranslatePageProvider";
import SettingPageProvider from "pages/setting/SettingPageProvider";
import BlogPageProvider from "pages/blog/BlogPageProvider";
import PortfolioPageProvider from "pages/portfolio/PortfolioPageProvider";
import TestimonialPageProvider from "pages/testimonial/TestimonialPageProvider";
import TeamPageProvider from "pages/team/TeamPageProvider";
import PartnerPageProvider from "pages/partner/PartnerPageProvider";
import SliderPageProvider from "pages/slider/SliderPageProvider";
import FaqPageProvider from "pages/faq/FaqPageProvider";
import GalleryPageProvider from "pages/gallery/GalleryPageProvider";
import ContactPageProvider from "pages/contact/ContactPageProvider";
import WidgetPageProvider from "pages/widget/WidgetPageProvider";
import SchedulePageProvider from "pages/schedule/SchedulePageProvider";
import RoomPageProvider from "pages/room/RoomPageProvider";

const routers = [
    {
        name: 'app',
        path: '/',
        element: <AppLayout/>,
        auth: true,
        permission: 'accept',
        children: [
            {
                name: 'index',
                index: true,
                permission: 'accept',
                element: <DashboardPageProvider/>,
            },
            {
                name: 'menu',
                path: 'menu',
                permission: 'menu.read',
                element: <MenuPageProvider/>,
            },
            {
                name: 'user',
                path: 'user',
                permission: 'user.read',
                element: <UserPageProvider/>,
            },
            {
                name: 'language',
                path: 'language',
                permission: 'language.read',
                element: <LanguagePageProvider/>,
            },
            {
                name: 'seo',
                path: 'seo',
                permission: 'seo_meta_tag.read',
                element: <SeoPageProvider/>,
            },
            {
                name: 'permission',
                path: 'permission',
                permission: 'permission.read',
                element: <PermissionPageProvider/>,
            },
            {
                name: 'translate',
                path: 'translate',
                permission: 'language.read',
                element: <TranslatePageProvider/>,
            },
            {
                name: 'setting',
                path: 'setting',
                permission: 'setting.read',
                element: <SettingPageProvider/>,
            },
            {
                name: 'service',
                path: 'service',
                permission: 'service.read',
                element: <ServicePageProvider/>,
            },
            {
                name: 'blog',
                path: 'blog',
                permission: 'blog.read',
                element: <BlogPageProvider/>,
            },
            {
                name: 'portfolio',
                path: 'portfolio',
                permission: 'portfolio.read',
                element: <PortfolioPageProvider/>,
            },
            {
                name: 'testimonial',
                path: 'testimonial',
                permission: 'testimonial.read',
                element: <TestimonialPageProvider/>,
            },
            {
                name: 'team',
                path: 'team',
                permission: 'team.read',
                element: <TeamPageProvider/>,
            },
            {
                name: 'partner',
                path: 'partner',
                permission: 'partner.read',
                element: <PartnerPageProvider/>,
            },
            {
                name: 'slider',
                path: 'slider',
                permission: 'slider.read',
                element: <SliderPageProvider/>,
            },
            {
                name: 'faq',
                path: 'faq',
                permission: 'faq.read',
                element: <FaqPageProvider/>,
            },
            {
                name: 'gallery',
                path: 'gallery',
                permission: 'gallery.read',
                element: <GalleryPageProvider/>,
            },
            {
                name: 'contact',
                path: 'contact',
                permission: 'contact.read',
                element: <ContactPageProvider/>,
            },
            {
                name: 'widget',
                path: 'widget',
                permission: 'widget.read',
                element: <WidgetPageProvider/>,
            },
            {
                name: 'schedule',
                path: 'schedule',
                permission: 'schedule.read',
                element: <SchedulePageProvider/>,
            },
            {
                name: 'room',
                path: 'room',
                permission: 'room.read',
                element: <RoomPageProvider/>,
            }
        ]
    },
    {
        name: 'auth',
        path: '/auth',
        permission: 'accept',
        element: <AuthLayout/>,
        children: [
            {
                name: 'login',
                index: true,
                path: 'login',
                permission: 'accept',
                element: <LoginPage/>,
            }
        ]
    },
    {
        path: '*',
        element: <Navigate to={'/'} replace={true}/>
    }
]

const routerMap = (routers) => routers.map(router => {
    if (router?.auth) {
        router.element = <AuthProvider>{router.element}</AuthProvider>
    }

    if (router?.children) {
        router.children = routerMap(router.children)
    }

    return router;
})

export default routerMap(routers);
