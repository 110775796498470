import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import UserApi from "api/user.api";
import {serviceUserDestroy, serviceUserSave, serviceUserUpdateAction} from "services/user.service";

const UserContext = createContext();

export const UserProvider = ({children}) => {

    const translateKey = 'crm.User';
    const permission = 'user';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(UserApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await serviceUserUpdateAction(id, action);
        if (res) await fetchDataSource();
    }

    const handleDelete = async (id) => {
        const res = await serviceUserDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await serviceUserSave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave
    };

    return <UserContext.Provider value={values}>
        {children}
    </UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext);
