import React, {useEffect, useState} from 'react';
import {can, translate} from "utils/helpers";
import {useTranslateContext} from "context/TranslateContext";
import {Table} from "components/ui";
import {useFetchLanguageList} from "hooks/useFetch";
import {MdTranslate} from "react-icons/all";

function TranslateTable(props) {

    const [columns, setColumns] = useState([]);
    const [languages, fetchLanguages] = useFetchLanguageList();
    const {
        translateKey,
        permission,
        handleModal,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery
    } = useTranslateContext();

    const handlerColumns = () => {
        const cols = [];
        languages.filter(i => i.id).filter(i => {
            const item = {
                title: i.name,
                dataIndex: i.code,
                key: i.id,
                render: (value, item) => {
                    return translate(item[i.code], {lang: i.code})
                }
            };
            if (i.code === 'az')  {
                item.fixed = 'left'
            }
            cols.push(item)
        })
        setColumns([...cols])
    }

    const actionRender = (row) => {
        return (
            <>
                {(can(permission + '.create') || can(permission + '.update')) && (
                    <button className="btn btn--primary btn--action" onClick={e => handleModal('form', true, row)}>
                        <MdTranslate/>
                    </button>
                )}
            </>
        )
    }

    useEffect(() => {
        fetchDataSource()
    }, [query])

    useEffect(() => {
        handlerColumns();
    }, [languages])

    useEffect(() => {
        fetchLanguages();
    }, [])

    return (
        <Table
            tableQuery={query}
            setTableQuery={handleQuery}
            dataSource={dataSource.data}
            total={dataSource.total}
            columns={columns}
            loading={tableLoading}
            sortingStart={1}
            actionWidth={40}
            actionButton={row => actionRender(row)}
        />
    );
}

export default TranslateTable;
