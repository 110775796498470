import React from 'react';
import PermissionPage from "pages/permission/PermissionPage";
import {PermissionProvider} from "context/PermissionContext";

function PermissionPageProvider(props) {
    return (
        <PermissionProvider>
            <PermissionPage/>
        </PermissionProvider>
    );
}

export default PermissionPageProvider;
