import React from 'react';
import ServicePage from "pages/service/ServicePage";
import {ServiceProvider} from "context/ServiceContext";

function ServicePageProvider(props) {
    return (
        <ServiceProvider>
            <ServicePage/>
        </ServiceProvider>
    );
}

export default ServicePageProvider;
