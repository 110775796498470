import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal} from "components/ui";
import {recursiveNotSearch, translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput, FormSelect} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useMenuContext} from "context/MenuContext";
import {useFetchMenuList} from "hooks/useFetch";
import {useForm} from "hooks/useForm";

function MenuFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useMenuContext();
    const [ready, setReady] = useState(false);
    const [menuList, fetchMenuList] = useFetchMenuList();

    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [parents, setParents] = useState([]);
    const types = [
        {id: '', name: translate('enum.Select')},
        {id: 'header', name: 'Header'},
        {id: 'footer', name: 'Footer'},
    ]

    const handlerForm = (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                name: item.translates && item.translates[i.code] ? item.translates[i.code].name : ''
            }
        })
        const customForm = {
            id: item.id || '',
            parent_id: item.parent_id || '',
            link: item.link || '',
            type: item.type || '',
            translates,
        };
        setReady(true);
        setValues({...customForm});
    }

    const handlerClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handlerSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let link = values.link;
        if (!values.link.startsWith('/')) link = '/' + link;
        const res = await handleSave({
            ...values,
            parent_id: values.parent_id || 0,
            link
        });
        setLoading(false)
        if (res) handlerClose();
    }

    const fetchParents = () => {
        let parents = menuList;
        if (tableRow?.id) parents = recursiveNotSearch('id', tableRow.id, menuList);
        setParents([...parents]);
    }

    useEffect(() => {
        handlerForm(tableRow);
    }, [tableRow])

    useEffect(() => {
        if (menuList.length > 0) {
            fetchParents();
        }
    }, [menuList])

    useEffect(() => {
        if (modal.form) {
            fetchMenuList();
        }
    }, [modal.form])

    return (
        <Modal
            title={translate('crm.Sidebar.Menus')}
            visible={modal.form}
            onClose={() => handlerClose()}
            className="w-full lg:!w-96"
        >
            {(ready && values.translates) && (
                <form onSubmit={handlerSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Parent')}
                            >
                                <FormSelect
                                    options={parents}
                                    value={values.parent_id}
                                    onChange={e => setField('parent_id', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Type')}
                                error={'type'}
                            >
                                <FormSelect
                                    options={types}
                                    value={values.type}
                                    onChange={e => setField('type', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Link')}
                                error={'link'}
                            >
                                <FormInput
                                    value={values.link}
                                    onChange={e => setField('link', e)}
                                />
                            </FormGroup>
                        </Col>

                        {languages.length > 0 && languages.map((i, index) => (
                            <Col span={24} key={index}>
                                <FormGroup
                                    label={translate(translateKey + '.Label.Name') + ' (' + i.name + ')'}
                                    error={`translates.${i.code}.name`}
                                >
                                    <FormInput
                                        value={values?.translates[i.code]?.name}
                                        onChange={e => setField('translates', {...values.translates, [i.code]: {name: e}})}
                                    />
                                </FormGroup>
                            </Col>
                        ))}

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default MenuFormModal;
