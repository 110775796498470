import stores from 'store'
import {
    setToken,
    setPermission,
    setUser
} from "store/module/auth.store";
import {API} from "utils/api";
import AuthApi from "api/auth.api";

const dispatch = stores.dispatch;

export const serviceAuthSetToken = (token) => {
    dispatch(setToken(token));
}

export const serviceAuthSetPermission = (permissions) => {
    dispatch(setPermission(permissions));
}

export const serviceLogoutAction = () => {
    serviceAuthSetToken(false);
    stores.dispatch(setUser({}));
    stores.dispatch(setPermission([]));
}

export const serviceAuthLoginDispatch = async (params) => {
    try {
        const res = await API.post(AuthApi.postAuthLogin, params);
        if (res) {
            serviceAuthSetToken(res);
        }
    }
    catch (e) {
        return false;
    }
}

export const serviceAuthLogoutFetch = async () => {
    try {
        const res = await API.get(AuthApi.getAuthLogout);
        if (res) {
            serviceLogoutAction()
        }
    }
    catch (e) {
        return false
    }
}

export const serviceAuthFetchCheckToken = async () => {
    try {
        const res = await API.get(AuthApi.getAuthCheckToken);
        stores.dispatch(setUser(res.user));
        stores.dispatch(setPermission(res.permissions));
    }
    catch (e) {
        return false;
    }
}

export const serviceAuthEditProfile = async (params) => {
    try {
        const res = await API.post(AuthApi.postAuthProfile, params);
        stores.dispatch(setUser(res.user));
        stores.dispatch(setPermission(res.permissions));
        return true;
    } catch (e) {
        return false;
    }
}
