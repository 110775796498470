import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {usePartnerContext} from "context/PartnerContext";
import PartnerTable from "pages/partner/components/PartnerTable";
import PartnerFormModal from "pages/partner/components/PartnerFormModal";
import PartnerFilter from "pages/partner/components/PartnerFilter";

function PartnerPage(props) {

    const {permission, modal, handleModal} = usePartnerContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <PartnerFilter/>
            <PartnerTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <PartnerFormModal/>}
                </>
            )}
        </Page>
    );
}

export default PartnerPage;
