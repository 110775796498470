import {translate} from "utils/helpers";
import {useState} from "react";
import MenuApi from "api/menu.api";
import {API} from "utils/api";
import PermissionApi from "api/permission.api";
import LanguageApi from "api/language.api";
import TranslateApi from "api/translate.api";
import WidgetApi from "api/widget.api";
import RoomApi from "api/room.api";

export const useFetch = (api) => {
    const [data, setData] = useState([]);

    const fetch = async () => {
        const res = await API.get(api);
        if (res.length > 0) {
            setData([
                {id: '', name: translate('enum.Select')},
                ...res,
            ]);
        }
    }
    return [data, fetch];
}

export const useFetchMenuList = () => {
    const [data, dataFetch] = useFetch(MenuApi.getSelect);
    return [data, dataFetch];
}

export const useFetchPermissionList = () => {
    const [data, dataFetch] = useFetch(PermissionApi.getSelect);
    return [data, dataFetch];
}

export const useFetchLanguageList = () => {
    const [data, dataFetch] = useFetch(LanguageApi.getSelect);
    return [data, dataFetch];
}

export const useFetchTranslateKeyList = () => {
    const [data, dataFetch] = useFetch(TranslateApi.getKeys);
    return [data, dataFetch];
}

export const useFetchWidgetList = () => {
    const [data, dataFetch] = useFetch(WidgetApi.getSelect);
    return [data, dataFetch];
}

export const useFetchRoomList = () => {
    const [data, dataFetch] = useFetch(RoomApi.getSelect);
    return [data, dataFetch];
}
