const ScheduleApi = {
    postCreate: '/crud/schedules',
    postAction: '/crud/schedules/:id/action',

    putUpdate: '/crud/schedules/:id',

    deleteDestroy: '/crud/schedules/:id',

    getIndex: '/crud/schedules',
    getSelect: '/crud/schedules/select',
    getId: '/crud/schedules/:id',
}

export default ScheduleApi;