import React from 'react';
import {Page} from "components/ui";
import {can} from "utils/helpers";
import {useContactContext} from "context/ContactContext";
import ContactTable from "pages/contact/components/ContactTable";
import ContactFormModal from "pages/contact/components/ContactFormModal";
import ContactFilter from "pages/contact/components/ContactFilter";

function ContactPage(props) {

    const {permission, modal, handleModal} = useContactContext();

    return (
        <Page>
            <ContactFilter/>
            <ContactTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <ContactFormModal/>}
                </>
            )}
        </Page>
    );
}

export default ContactPage;
