import React from 'react';
import UserPage from "pages/user/UserPage";
import {UserProvider} from "context/UserContext";

function UserPageProvider(props) {
    return (
        <UserProvider>
            <UserPage/>
        </UserProvider>
    );
}

export default UserPageProvider;
