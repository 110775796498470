import React, {useEffect, useState} from 'react';
import {translate} from "utils/helpers";
import {Button, FormGroup, Modal} from "components/ui";
import {useMenuContext} from "context/MenuContext";
import {useFetchWidgetList} from "hooks/useFetch";
import {Alert, Col, Row} from "antd";
import {FormSelect} from "components/ui/form";
import {useForm} from "hooks/useForm";
import {API} from "utils/api";
import MenuApi from "api/menu.api";

function MenuWidgetFormModal({item, visible, setVisible, fetchItem}) {

    const {translateKey} = useMenuContext();
    const [widgets, fetchWidgets] = useFetchWidgetList();
    const [items, setItems] = useState([]);

    const {values, setField, loading, setLoading} = useForm({
        widget_id: '',
    });

    const handlerClose = () => {
        setVisible(false);
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await API.post(MenuApi.postWidgets.replace(':id', item.id), values);
        if (res) {
            fetchItem();
            handlerClose();
        }
        setLoading(false);
    }

    useEffect(() => {
        if (widgets.length > 0) {
            let list = widgets;
            if (item?.widgets?.length > 0) {
                list = widgets.filter(w => !item?.widgets.find(i => i.widget_id === w.id))
            }
            setItems(list);
        }
    }, [widgets])

    useEffect(() => {
        if (visible) {
            fetchWidgets();
        }
    }, [visible])

    return (
        <Modal
            title={translate(translateKey + '.Label.BtnWidget')}
            visible={visible}
            onClose={() => handlerClose()}
            className="lg:!w-96 !w-full"
        >
            {items.filter(i => i.id !== '').length > 0 && (
                <form onSubmit={handleForm}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormGroup>
                                <FormSelect
                                    allowClear={false}
                                    showSearch={false}
                                    value={values.widget_id}
                                    onChange={e => setField('widget_id', e)}
                                    options={items.filter(i => i.id !== '').map(i => ({id: i.id, name: i?.title || i.name}))}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Add')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}

            {items.filter(i => i.id !== '').length === 0 && (
                <Alert message={translate(translateKey + '.Label.AlertNotWidget')}/>
            )}
        </Modal>
    );
}

export default MenuWidgetFormModal;
