import React, {useEffect, useState} from 'react';
import {FormInput} from "components/ui/form/index";
import {getYoutubeId} from "utils/helpers";
import {FormGroup} from "components/ui/index";
import Modal from "components/ui/modal/Modal";
import {FaPlay} from "react-icons/all";

function FormYoutubeVideo({onChange, label, error, value}) {
    const [text, setText] = useState('');
    const [preview, setPreview] = useState('');
    const [photo, setPhoto] = useState('');
    const [visible, setVisible] = useState('');
    const [youtubeId, setYoutubeId] = useState('');

    const handlePreview = () => setPreview(youtubeId ? `https://www.youtube.com/embed/${youtubeId}?autoplay=1` : '');

    const handlePhoto = () => setPhoto(youtubeId ? `http://i3.ytimg.com/vi/${youtubeId}/maxresdefault.jpg` : '')

    const handleChange = (value) => {
        setText(value);
        const youtubeId = getYoutubeId(value);
        if (youtubeId) {
            setYoutubeId(youtubeId);
            if (onChange) {
                onChange(youtubeId);
            }
        }
        else {
            setYoutubeId('')
            if (onChange) {
                onChange('');
            }
        }
    }

    const handlePlay = () => {
        setVisible(true);
        handlePreview()
    }

    useEffect(() => {
        handlePhoto();
    }, [youtubeId])

    return (
        <div>
            <FormGroup
                label={label}
                error={error}
            >
                <FormInput
                    value={text}
                    onChange={e => handleChange(e)}
                />
            </FormGroup>
            {photo && (
                <div className="aspect-w-16 aspect-h-9 mt-3 rounded-lg overflow-hidden relative">
                    <div className="dark:bg-gray-600 p-2">
                        <img src={photo} alt=""/>
                        <div className="absolute inset-0 flex items-center justify-center">
                            <button onClick={() => handlePlay()} className="w-14 h-12 rounded-2xl flex items-center justify-center bg-red-500 text-xl" type="button">
                                <FaPlay/>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {preview && (
                <Modal
                    visible={visible}
                    onClose={() => {
                        setVisible(false);
                        setPreview('')
                    }}
                >
                    <div className="aspect-w-16 aspect-h-9 mt-3 rounded-lg overflow-hidden">
                        <div className="dark:bg-gray-600 p-2">
                            <iframe className="w-full h-full" src={preview} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default FormYoutubeVideo;
