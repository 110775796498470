import React, {useEffect, useState} from 'react';
import {Button, Card, FormGroup, Modal} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormInput, FormSelect} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useUserContext} from "context/UserContext";
import {useForm} from "hooks/useForm";
import {useFetchPermissionList} from "hooks/useFetch";

function UserFormModal(props) {

    const {modal, handleModal, tableRow, translateKey, handleSave} = useUserContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [permissions, fetchPermissions] = useFetchPermissionList();

    const handleForm = async (item = {}) => {
        const customForm = {
            id: item.id || '',
            email: item.email || '',
            password: item.password || '',
            name: item.name || '',
            surname: item.surname || '',
            permission_id: item.permission_id || 1,
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    useEffect(() => {
        if (modal.form) {
            fetchPermissions();
        }
    }, [modal.form])

    return (
        <Modal
            title={translate('crm.Sidebar.Users')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="lg:!w-96"
        >
            {(ready) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Email')}
                                error="email"
                            >
                                <FormInput
                                    value={values.email}
                                    onChange={e => setField('email', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Name')}
                                error="name"
                            >
                                <FormInput
                                    value={values.name}
                                    onChange={e => setField('name', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Surname')}
                                error="surname"
                            >
                                <FormInput
                                    value={values.surname}
                                    onChange={e => setField('surname', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Password')}
                                error="password"
                            >
                                <FormInput
                                    value={values.password}
                                    onChange={e => setField('password', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Permission')}
                                error="permission_id"
                            >
                                <FormSelect
                                    options={permissions}
                                    value={values.permission_id}
                                    onChange={e => setField('permission_id', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default UserFormModal;
