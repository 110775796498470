const ContactApi = {
    postCreate: '/crud/contacts',
    postAction: '/crud/contacts/:id/action',

    putUpdate: '/crud/contacts/:id',

    deleteDestroy: '/crud/contacts/:id',

    getIndex: '/crud/contacts',
    getSelect: '/crud/contacts/select',
    getId: '/crud/contacts/:id',
}

export default ContactApi;