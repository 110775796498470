import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useLanguageContext} from "context/LanguageContext";
import LanguageTable from "pages/language/components/LanguageTable";
import LanguageFormModal from "pages/language/components/LanguageFormModal";
import LanguageFilter from "pages/language/components/LanguageFilter";

function LanguagePage(props) {

    const {permission, modal, handleModal} = useLanguageContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <LanguageFilter/>
            <LanguageTable/>
            {(modal.form && (can(permission + '.create') || can(permission + '.update'))) && <LanguageFormModal/>}
        </Page>
    );
}

export default LanguagePage;
