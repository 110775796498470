import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useFaqContext} from "context/FaqContext";
import FaqTable from "pages/faq/components/FaqTable";
import FaqFormModal from "pages/faq/components/FaqFormModal";
import FaqFilter from "pages/faq/components/FaqFilter";

function FaqPage(props) {

    const {permission, modal, handleModal} = useFaqContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <FaqFilter/>
            <FaqTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <FaqFormModal/>}
                </>
            )}
        </Page>
    );
}

export default FaqPage;
