import React from 'react';
import TranslatePage from "pages/translate/TranslatePage";
import {TranslateProvider} from "context/TranslateContext";

function TranslatePageProvider(props) {
    return (
        <TranslateProvider>
            <TranslatePage/>
        </TranslateProvider>
    );
}

export default TranslatePageProvider;
