import React from 'react';
import SchedulePage from "pages/schedule/SchedulePage";
import {ScheduleProvider} from "context/ScheduleContext";

function SchedulePageProvider(props) {
    return (
        <ScheduleProvider>
            <SchedulePage/>
        </ScheduleProvider>
    );
}

export default SchedulePageProvider;
