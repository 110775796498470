import React, {useEffect, useState} from 'react';
import {Button, Card, FormGroup, Modal} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormHtmlEditor, FormInput, FormPhoto} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useTeamContext} from "context/TeamContext";
import {useForm} from "hooks/useForm";

function TeamFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useTeamContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [preview, setPreview] = useState('');

    const handleForm = async (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                fullname: item.translates && item.translates[i.code] ? item.translates[i.code].fullname : '',
                profession: item.translates && item.translates[i.code] ? item.translates[i.code].profession : '',
                about: item.translates && item.translates[i.code] ? item.translates[i.code].about : ''
            }
        })
        const customForm = {
            id: item.id || '',
            photo: '',
            social_pages: item?.social_pages || {
                facebook: '',
                instagram: '',
                linkedin: '',
                twitter: '',
            },
            translates,
        };
        if (item?.photo?.original) {
            setPreview(item.photo.original)
        }
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Teams')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="lg:!w-[800px] !w-full"
        >
            {(ready && values.translates) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={8}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Photo')}
                                error="photo"
                            >
                                <FormPhoto
                                    height={193}
                                    preview={preview}
                                    value={values.photo}
                                    onChange={e => setField('photo', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={16}>
                            <Row gutter={[16, 16]}>
                                {Object.keys(values.social_pages).map((i, index) => (
                                    <Col span={24} key={index}>
                                        <FormGroup
                                            label={i}
                                        >
                                            <FormInput
                                                value={values.social_pages[i]}
                                                onChange={e => setField('social_pages', {
                                                    ...values.social_pages,
                                                    [i]: e
                                                })}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                        </Col>


                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Card title={i.name} key={index}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Fullname')}
                                                error={`translates.${i.code}.fullname`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.fullname}
                                                    onChange={e => setField('translates', {
                                                        ...values.translates,
                                                        [i.code]: {...values.translates[i.code], fullname: e}
                                                    })}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Profession')}
                                                error={`translates.${i.code}.profession`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.profession}
                                                    onChange={e => setField('translates', {
                                                        ...values.translates,
                                                        [i.code]: {...values.translates[i.code], profession: e}
                                                    })}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.About')}
                                                error={`translates.${i.code}.about`}
                                            >
                                                <FormHtmlEditor
                                                    value={tableRow?.translates && tableRow?.translates[i.code]?.about}
                                                    onChange={e => setField('translates', {
                                                        ...values.translates,
                                                        [i.code]: {...values.translates[i.code], about: e}
                                                    })}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default TeamFormModal;
