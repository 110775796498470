import React from 'react';
import TestimonialPage from "pages/testimonial/TestimonialPage";
import {TestimonialProvider} from "context/TestimonialContext";

function TestimonialPageProvider(props) {
    return (
        <TestimonialProvider>
            <TestimonialPage/>
        </TestimonialProvider>
    );
}

export default TestimonialPageProvider;
