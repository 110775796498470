const PortfolioApi = {
    postCreate: '/crud/portfolios',
    postAction: '/crud/portfolios/:id/action',
    postOrder: '/crud/portfolios/:id/order',

    putUpdate: '/crud/portfolios/:id',

    deleteDestroy: '/crud/portfolios/:id',

    getIndex: '/crud/portfolios',
    getSelect: '/crud/portfolios/select',
    getId: '/crud/portfolios/:id',
}

export default PortfolioApi;
