import {createContext, useContext} from "react";

const SettingContext = createContext();

export const SettingProvider = ({children}) => {

    const translateKey = 'crm.Setting';
    const permission = 'setting';

    const values = {
        translateKey,
        permission
    };

    return <SettingContext.Provider value={values}>
        {children}
    </SettingContext.Provider>
}

export const useSettingContext = () => useContext(SettingContext);
