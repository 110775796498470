import React, {useEffect, useState} from 'react';
import {serviceSettingItem, serviceSettingSave} from "services/setting.service";
import {useSettingContext} from "context/SettingContext";
import {useForm} from "hooks/useForm";
import {translate} from "utils/helpers";
import {Button, Card, FormGroup} from "components/ui";
import {Col, Row} from "antd";
import {FormPhoto} from "components/ui/form";

function SettingLogo(props) {

    const tab = 'logo'
    const {translateKey} = useSettingContext();
    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [preview, setPreview] = useState({});

    const handleForm = (item = {}) => {
        const customForm = {
            logo: '',
            admin_logo_light: '',
            admin_logo_dark: '',
            favicon: '',
            footer: '',
            mobile: '',
            wallpaper: '',
        };
        setValues({...customForm});
        setPreview({
            admin_logo_light_path: item.admin_logo_light_path,
            admin_logo_dark_path: item.admin_logo_dark_path,
            favicon_path: item.favicon_path,
            footer_path: item.footer_path,
            logo_path: item.logo_path,
            mobile_path: item.mobile_path,
            wallpaper_path: item.wallpaper_path,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await serviceSettingSave(values, tab);
        setLoading(false);
    }

    const fetchByKey = async () => {
        const res = await serviceSettingItem(tab);
        handleForm(res?.value)
    }

    useEffect(() => {
        if (tab) {
            fetchByKey();
        }
    }, [tab])

    return (
        <Card className={'lg:w-[800px]'} title={translate('crm.Sidebar.Logo')}>
            <form onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>
                    <Col lg={6} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.Logo')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.logo}
                                preview={preview.logo_path}
                                onChange={e => setField('logo', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.FooterLogo')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.footer}
                                preview={preview.footer_path}
                                onChange={e => setField('footer', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.MobileLogo')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.mobile}
                                preview={preview.mobile_path}
                                onChange={e => setField('mobile', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.Favicon')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.favicon}
                                preview={preview.favicon_path}
                                onChange={e => setField('favicon', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.AdminLogin')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.admin_logo_light}
                                preview={preview.admin_logo_light_path}
                                onChange={e => setField('admin_logo_light', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.AdminMain')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.admin_logo_dark}
                                preview={preview.admin_logo_dark_path}
                                onChange={e => setField('admin_logo_dark', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={12} xs={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.Wallpaper')}
                            elementClass={'!h-auto'}
                        >
                            <FormPhoto
                                height={150}
                                value={values?.wallpaper}
                                preview={preview.wallpaper_path}
                                onChange={e => setField('wallpaper', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24} className="!flex justify-end">
                        <Button loading={loading} type={'submit'}>
                            {translate('button.Save')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Card>
    );
}

export default SettingLogo;
