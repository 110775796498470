const FaqApi = {
    postCreate: '/crud/faqs',
    postAction: '/crud/faqs/:id/action',
    postOrder: '/crud/faqs/:id/order',

    putUpdate: '/crud/faqs/:id',

    deleteDestroy: '/crud/faqs/:id',

    getIndex: '/crud/faqs',
    getSelect: '/crud/faqs/select',
    getId: '/crud/faqs/:id',
}

export default FaqApi;
