import React, {useEffect, useState} from 'react';
import {useForm} from "hooks/useForm";
import {serviceSettingItem, serviceSettingSave} from "services/setting.service";
import {translate} from "utils/helpers";
import {Button, Card, FormGroup} from "components/ui";
import {Col, Row} from "antd";
import {FormInput} from "components/ui/form";
import {FiMinus, FiPlus} from "react-icons/all";

function SettingSocialPage(props) {

    const tab = 'social-page'
    const {values, setValues, setLoading, loading} = useForm({});

    const handleForm = (item = {}) => {
        let customForm = [{url: '', icon: ''}];
        if (item.length > 0) customForm = item;
        setValues([...customForm]);
    }

    const handleAction = (action, index = null) => {
        if (action === 'add') {
            setValues(prevState => {
                return [...prevState, {url: '', key: ''}]
            })
        } else {
            values.splice(index, 1);
            setValues([...values])
        }
    }

    const handleChangeObj = (value, index, field) => {
        const item = Object.assign({}, values[index])
        item[field] = value;
        values[index] = item;
        setValues([...values])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await serviceSettingSave(values, tab);
        setLoading(false);
    }

    const fetchByKey = async () => {
        const res = await serviceSettingItem(tab);
        handleForm(res?.value)
    }

    useEffect(() => {
        if (tab) {
            fetchByKey();
        }
    }, [tab])

    return (
        <Card title={translate('crm.Sidebar.SocialPage')} className="lg:w-[600px]">
            <form onSubmit={handleSubmit}>
                <div className="space-y-2">
                    {values.length > 0 && values.map((i, index) => (
                        <Row gutter={[26, 26]} key={index}>
                            <Col lg={12} xs={24}>
                                <FormGroup
                                    label={index === 0 ? 'Icon' : ''}
                                >
                                    <FormInput
                                        onChange={e => handleChangeObj(e, index, 'icon')}
                                        value={i.icon}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={12} xs={24}>
                                <FormGroup
                                    label={index === 0 ? 'Link' : ''}
                                >
                                    <FormInput
                                        value={i.url}
                                        onChange={e => handleChangeObj(e, index, 'url')}
                                        suffix={
                                            <>
                                                {index > 0 && (
                                                    <Button className="w-[25px] !h-[25px] !p-0 justify-center" property="red" onClick={() => handleAction('remove', index)}>
                                                        <FiMinus/>
                                                    </Button>
                                                )}
                                                {index === 0 && (
                                                    <Button className="w-[25px] !h-[25px] !p-0 justify-center" onClick={() => handleAction('add')}>
                                                        <FiPlus/>
                                                    </Button>
                                                )}
                                            </>
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    ))}
                </div>
                <div className="mt-5">
                    <Button loading={loading} type={'submit'}>
                        {translate('button.Save')}
                    </Button>
                </div>
            </form>
        </Card>
    );
}

export default SettingSocialPage;
