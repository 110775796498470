import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import {Button, Card, FormGroup} from "components/ui";
import {FormInput, FormSelect} from "components/ui/form";
import {translate} from "utils/helpers";
import {BiReset, FiFilter} from "react-icons/all";
import {useUserContext} from "context/UserContext";
import {useFetchPermissionList} from "hooks/useFetch";

function UserFilter(props) {

    const {translateKey, handleQuery} = useUserContext();
    const initialFilter = {
        fullname: '',
        email: '',
        permission: ''
    }
    const [filter, setFilter] = useState(initialFilter);
    const [isFilter, setIsFilter] = useState(false);
    const [permissions, fetchPermissions] = useFetchPermissionList();

    const handleFilter = () => {
        const customQuery = {};
        if (filter.fullname) customQuery.fullname = filter.fullname;
        if (filter.email) customQuery.email = filter.email;
        if (filter.permission) customQuery.permission = filter.permission;
        handleQuery(customQuery);
        setIsFilter(true);
    }

    const handleReset = () => {
        setFilter(initialFilter);
        handleQuery(false);
        setIsFilter(false);
    }

    useEffect(() => {
        fetchPermissions();
    }, [])

    return (
        <Card className="mb-5">
            <div className="filter-box">
                <div className="flex-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                            <FormGroup
                                label={translate(translateKey + '.Label.FullName')}
                            >
                                <FormInput
                                    value={filter.fullname}
                                    onPressEnter={e => e.trim() ? handleFilter() : false}
                                    onChange={e => setFilter(f => ({...f, fullname: e}))}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={24} lg={8}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Email')}
                            >
                                <FormInput
                                    value={filter.email}
                                    onPressEnter={e => e.trim() ? handleFilter() : false}
                                    onChange={e => setFilter(f => ({...f, email: e}))}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={24} lg={8}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Permission')}
                            >
                                <FormSelect
                                    options={permissions}
                                    value={filter.permission}
                                    onChange={e => setFilter(f => ({...f, permission: e}))}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="filter-box-buttons lg:pt-[25px]">
                    <Button
                        disabled={!Object.keys(filter).filter(i => filter[i]?.toString()?.trim()).length}
                        onClick={() => handleFilter()}
                        className="filter-box-button"
                        icon={<FiFilter/>}
                    >
                        {translate( 'button.Filter')}
                    </Button>

                    {isFilter > 0 && (
                        <Button
                            onClick={() => handleReset()}
                            className="filter-box-button"
                            property={'red'}
                            icon={<BiReset/>}
                        >
                            {translate( 'button.Reset')}
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
}

export default UserFilter;
