import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import GalleryApi from "api/gallery.api";
import {serviceGalleryDestroy, serviceGallerySave, serviceGalleryUpdateAction} from "services/gallery.service";

const GalleryContext = createContext();

export const GalleryProvider = ({children}) => {

    const translateKey = 'crm.Gallery';
    const permission = 'gallery';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(GalleryApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await serviceGalleryUpdateAction(id, action);
        if (res) await fetchDataSource();
    }

    const handleDelete = async (id) => {
        const res = await serviceGalleryDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await serviceGallerySave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave
    };

    return <GalleryContext.Provider value={values}>
        {children}
    </GalleryContext.Provider>
}

export const useGalleryContext = () => useContext(GalleryContext);
