import React from 'react';
import SeoPage from "pages/seo/SeoPage";
import {SeoProvider} from "context/SeoContext";

function SeoPageProvider(props) {
    return (
        <SeoProvider>
            <SeoPage/>
        </SeoProvider>
    );
}

export default SeoPageProvider;
