import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import BlogApi from "api/blog.api";
import {serviceBlogDestroy, serviceBlogSave, serviceBlogUpdateAction} from "services/blog.service";

const BlogContext = createContext();

export const BlogProvider = ({children}) => {

    const translateKey = 'crm.Blog';
    const permission = 'blog';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(BlogApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await serviceBlogUpdateAction(id, action);
        if (res) await fetchDataSource();
    }

    const handleDelete = async (id) => {
        const res = await serviceBlogDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await serviceBlogSave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave
    };

    return <BlogContext.Provider value={values}>
        {children}
    </BlogContext.Provider>
}

export const useBlogContext = () => useContext(BlogContext);
