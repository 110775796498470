import React from 'react';
import PortfolioPage from "pages/portfolio/PortfolioPage";
import {PortfolioProvider} from "context/PortfolioContext";

function PortfolioPageProvider(props) {
    return (
        <PortfolioProvider>
            <PortfolioPage/>
        </PortfolioProvider>
    );
}

export default PortfolioPageProvider;
