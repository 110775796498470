import React, {useEffect, useState} from 'react';
import {translate} from "utils/helpers";
import {Button, FormGroup, Modal} from "components/ui";
import {serviceAppSetError} from "services/app.service";
import {Col, Row} from "antd";
import {FormSelect, FormTextarea} from "components/ui/form";
import {API, api} from "utils/api";
import SeoApi from "api/seo.api";
import {useForm} from "hooks/useForm";
import {useSeoContext} from "context/SeoContext";

function SeoRobotFormModal(props) {

    const {modal, handleModal} = useSeoContext();

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await API.post(SeoApi.postRobots, values);
        if (res) handleClose();
        setLoading(false)
    }

    const handleForm = async (item = {}) => {
        const customForm = {
            text: item.text || '',
        };
        await setValues({...customForm});
    }

    const fetchRobot = async () => {
        const res = await API.get(SeoApi.getRobots);
        if (res) {
            await handleForm({text: res})
        }
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('robot', false);
    }

    useEffect(() => {
        if (modal.robot) {
            fetchRobot();
        }
    }, [modal.robot])

    return (
        <Modal
            title={'Robots.txt'}
            visible={modal.robot}
            onClose={() => handleClose()}
            backdrop={true}
            className="lg:!w-[300px] !w-full"
        >
            <form onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>

                    <Col xs={24} lg={24}>
                        <FormGroup
                            label={'Text'}
                            error="text"
                        >
                            <FormTextarea
                                rows={10}
                                value={values.text}
                                onChange={e => setField('text', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24} className="flex justify-end">
                        <Button loading={loading} type={'submit'} block={true}>
                            {translate('button.Save')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
}

export default SeoRobotFormModal;
