import React from 'react';
import WidgetPage from "pages/widget/WidgetPage";
import {WidgetProvider} from "context/WidgetContext";

function WidgetPageProvider(props) {
    return (
        <WidgetProvider>
            <WidgetPage/>
        </WidgetProvider>
    );
}

export default WidgetPageProvider;
