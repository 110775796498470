import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import TranslateApi from "api/translate.api";
import {serviceTranslateSave} from "services/translate.service";
import {serviceAppFetchStart} from "services/app.service";

const TranslateContext = createContext();

export const TranslateProvider = ({children}) => {

    const translateKey = 'crm.Language';
    const permission = 'language';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(TranslateApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleSave = async (form) => {
        const res = await serviceTranslateSave(form)
        if (res) {
            await serviceAppFetchStart();
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleSave
    };

    return <TranslateContext.Provider value={values}>
        {children}
    </TranslateContext.Provider>
}

export const useTranslateContext = () => useContext(TranslateContext);
