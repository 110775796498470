import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormHtmlEditor, FormInput, FormPhoto, FormSelect, FormTextarea} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useTestimonialContext} from "context/TestimonialContext";
import {useForm} from "hooks/useForm";

function TestimonialFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useTestimonialContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});
    const [preview, setPreview] = useState('');

    const handleForm = async (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                fullname: item.translates && item.translates[i.code] ? item.translates[i.code].fullname : '',
                profession: item.translates && item.translates[i.code] ? item.translates[i.code].profession : '',
                message: item.translates && item.translates[i.code] ? item.translates[i.code].message : ''
            }
        })
        const customForm = {
            id: item.id || '',
            photo: '',
            review: item?.review || 1,
            translates,
        };
        if (item?.photo?.original) {
            setPreview(item.photo.original)
        }
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.Testimonials')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="w-full"
        >
            {(ready && values.translates) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={9}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Photo')}
                                error="photo"
                            >
                                <FormPhoto
                                    height={150}
                                    preview={preview}
                                    value={values.photo}
                                    onChange={e => setField('photo', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col span={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Review')}
                                error="review"
                            >
                                <FormSelect
                                    allowClear={false}
                                    showSearch={false}
                                    options={[
                                        {id: 1, name: 1},
                                        {id: 2, name: 2},
                                        {id: 3, name: 3},
                                        {id: 4, name: 4},
                                        {id: 5, name: 5}
                                    ]}
                                    value={values.review}
                                    onChange={e => setField('review', e)}
                                />
                            </FormGroup>
                        </Col>


                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Card title={i.name} key={index}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Fullname')}
                                                error={`translates.${i.code}.fullname`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.fullname}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], fullname: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Profession')}
                                                error={`translates.${i.code}.profession`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.profession}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], profession: e}})}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Message')}
                                                error={`translates.${i.code}.message`}
                                            >
                                                <FormTextarea
                                                    rows={6}
                                                    value={values?.translates[i.code]?.message}
                                                    onChange={e => setField('translates', {...values.translates, [i.code]: {...values.translates[i.code], message: e}})}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default TestimonialFormModal;
