const BlogApi = {
    postCreate: '/crud/blogs',
    postAction: '/crud/blogs/:id/action',

    putUpdate: '/crud/blogs/:id',

    deleteDestroy: '/crud/blogs/:id',

    getIndex: '/crud/blogs',
    getSelect: '/crud/blogs/select',
    getId: '/crud/blogs/:id',
}

export default BlogApi;