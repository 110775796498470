import React from 'react';
import RoomPage from "pages/room/RoomPage";
import {RoomProvider} from "context/RoomContext";

function RoomPageProvider(props) {
    return (
        <RoomProvider>
            <RoomPage/>
        </RoomProvider>
    );
}

export default RoomPageProvider;
