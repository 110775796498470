import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useUserContext} from "context/UserContext";
import UserTable from "pages/user/components/UserTable";
import UserFormModal from "pages/user/components/UserFormModal";
import UserFilter from "pages/user/components/UserFilter";

function UserPage(props) {

    const {permission, modal, handleModal} = useUserContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <UserFilter/>
            <UserTable/>
            {(modal.form && (can(permission + '.create') || can(permission + '.update'))) && <UserFormModal/>}
        </Page>
    );
}

export default UserPage;
