import {useState} from "react";

export const useForm = (initialValue = {}) => {
    const [values, setValues] = useState(initialValue);
    const [loading, setLoading] = useState(false);

    const setField = (key, value) => {
        setValues(f => ({
            ...f,
            [key]: value
        }))
    }

    return {
        values,
        setValues,
        setField,
        loading,
        setLoading
    }
}
