import React, {useEffect} from 'react';
import {useSettingContext} from "context/SettingContext";
import {useForm} from "hooks/useForm";
import {serviceSettingItem, serviceSettingSave} from "services/setting.service";
import {translate} from "utils/helpers";
import {Button, Card, FormGroup} from "components/ui";
import {Col, Row} from "antd";
import {FormTextarea} from "components/ui/form";

function SettingHtml(props) {
    const tab = 'html'
    const {translateKey} = useSettingContext();
    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = (item = {}) => {
        const customForm = {
            body: item.body || '',
            head: item.head || '',
        };
        setValues({...customForm});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await serviceSettingSave(values, tab);
        setLoading(false);
    }

    const fetchByKey = async () => {
        const res = await serviceSettingItem(tab);
        handleForm(res?.value)
    }

    useEffect(() => {
        if (tab) {
            fetchByKey();
        }
    }, [tab])

    return (
        <Card title={translate('crm.Sidebar.Html')}>
            <form onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.HeadTag')}
                            elementClass={'!h-auto'}
                        >
                            <FormTextarea
                                rows={10}
                                value={values?.head}
                                onChange={e => setField('head', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24}>
                        <FormGroup
                            label={translate(translateKey + '.Label.BodyTag')}
                            elementClass={'!h-auto'}
                        >
                            <FormTextarea
                                rows={10}
                                value={values?.body}
                                onChange={e => setField('body', e)}
                            />
                        </FormGroup>
                    </Col>

                    <Col span={24} className="!flex justify-end">
                        <Button loading={loading} type={'submit'}>
                            {translate('button.Save')}
                        </Button>
                    </Col>
                </Row>
            </form>
        </Card>
    );
}

export default SettingHtml;
