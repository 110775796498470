const GalleryApi = {
    postCreate: '/crud/galleries',
    postAction: '/crud/galleries/:id/action',

    putUpdate: '/crud/galleries/:id',

    deleteDestroy: '/crud/galleries/:id',

    getIndex: '/crud/galleries',
    getSelect: '/crud/galleries/select',
    getId: '/crud/galleries/:id',
}

export default GalleryApi;
