import React from 'react';
import PartnerPage from "pages/partner/PartnerPage";
import {PartnerProvider} from "context/PartnerContext";

function PartnerPageProvider(props) {
    return (
        <PartnerProvider>
            <PartnerPage/>
        </PartnerProvider>
    );
}

export default PartnerPageProvider;
