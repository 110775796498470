import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import SeoApi from "api/seo.api";
import {serviceSeoDestroy, serviceSeoSave, serviceSeoUpdateAction} from "services/seo.service";

const SeoContext = createContext();

export const SeoProvider = ({children}) => {

    const translateKey = 'crm.SeoMetaTag';
    const permission = 'seo_meta_tag';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false,
        robot: false,
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(SeoApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await serviceSeoUpdateAction(id, action);
        if (res) await fetchDataSource();
    }

    const handleDelete = async (id) => {
        const res = await serviceSeoDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await serviceSeoSave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave
    };

    return <SeoContext.Provider value={values}>
        {children}
    </SeoContext.Provider>
}

export const useSeoContext = () => useContext(SeoContext);
