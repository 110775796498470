import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Modal, Card} from "components/ui";
import {translate} from "utils/helpers";
import {Col, Row} from "antd";
import {FormHtmlEditor, FormInput, FormTextarea} from "components/ui/form";
import {serviceAppSetError} from "services/app.service";
import {useAppState} from "store/module/app.store";
import {useSeoContext} from "context/SeoContext";
import {useForm} from "hooks/useForm";

function SeoFormModal(props) {

    const {languages} = useAppState();
    const {modal, handleModal, tableRow, translateKey, handleSave} = useSeoContext();
    const [ready, setReady] = useState(false)

    const {values, setField, setValues, setLoading, loading} = useForm({});

    const handleForm = async (item = {}) => {
        let translates = {};
        languages.filter(i => {
            translates[i.code] = {
                name: item.translates && item.translates[i.code] ? item.translates[i.code].name : '',
                title: item.translates && item.translates[i.code] ? item.translates[i.code].title : '',
                description: item.translates && item.translates[i.code] ? item.translates[i.code].description : '',
                text: item.translates && item.translates[i.code] ? item.translates[i.code].text : '',
            }
        })
        const customForm = {
            id: item.id || '',
            url: item.url || '',
            seo_type: item.seo_type || 'static',
            filters: item.filters || '',
            other_meta: item.other_meta || '',
            translates,
        };
        await setValues({...customForm});
        setReady(true);
    }

    const handleClose = () => {
        serviceAppSetError({});
        handleModal('form', false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!values.url.startsWith('/')) {
            values.url = '/' + values.url;
        }
        const res = await handleSave(values);
        if (res) handleClose();
        setLoading(false)
    }

    useEffect(() => {
        handleForm(tableRow);
    }, [tableRow])

    return (
        <Modal
            title={translate('crm.Sidebar.SeoMetaTag')}
            visible={modal.form}
            onClose={() => handleClose()}
            className="w-full"
        >
            {(ready && values.translates) && (
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} lg={24}>
                            <FormGroup
                                label={translate(translateKey + '.Label.Url')}
                                error="url"
                            >
                                <FormInput
                                    value={values.url}
                                    onChange={e => setField('url', e)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={24} lg={24} className="space-y-3">
                            {languages.length > 0 && languages.map((i, index) => (
                                <Card title={i.name} key={index}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Name') + ' (' + i.name + ')'}
                                                error={`translates.${i.code}.name`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.name}
                                                    onChange={e => setValues(f => ({
                                                        ...f,
                                                        translates: {
                                                            ...f.translates,
                                                            [i.code]: {...f.translates[i.code], name: e}
                                                        }
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Title') + ' / Meta title (' + i.name + ')'}
                                                error={`translates.${i.code}.title`}
                                            >
                                                <FormInput
                                                    value={values?.translates[i.code]?.title}
                                                    onChange={e => setValues(f => ({
                                                        ...f,
                                                        translates: {
                                                            ...f.translates,
                                                            [i.code]: {...f.translates[i.code], title: e}
                                                        }
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Description') + ' / Meta Description (' + i.name + ')'}
                                                error={`translates.${i.code}.description`}
                                            >
                                                <FormTextarea
                                                    rows={8}
                                                    value={values?.translates[i.code]?.description}
                                                    onChange={e => setValues(f => ({
                                                        ...f,
                                                        translates: {
                                                            ...f.translates,
                                                            [i.code]: {
                                                                ...f.translates[i.code],
                                                                description: e
                                                            }
                                                        }
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <FormGroup
                                                label={translate(translateKey + '.Label.Text') + ' (' + i.name + ')'}
                                            >
                                                <FormHtmlEditor
                                                    value={tableRow?.translates ? tableRow?.translates[i.code]?.text : ''}
                                                    minimum={true}
                                                    onChange={e => setValues(f => ({
                                                        ...f,
                                                        translates: {
                                                            ...f.translates,
                                                            [i.code]: {...f.translates[i.code], text: e}
                                                        }
                                                    }))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>

                        <Col span={24} className="!flex justify-end">
                            <Button loading={loading} type={'submit'}>
                                {translate('button.Save')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Modal>
    );
}

export default SeoFormModal;
