import React from 'react';
import {translate} from "utils/helpers";
import {Button, FormGroup} from "components/ui";
import {FormInput, FormPassword} from "components/ui/form";
import {Col, Row} from "antd";
import {serviceAuthLoginDispatch} from "services/auth.service";
import {useForm} from "hooks/useForm";

function LoginPage(props) {

    const {values, setField, loading, setLoading} = useForm({
        email: '',
        password: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        await serviceAuthLoginDispatch(values);
        setLoading(false)
    }

    return (
        <>
            <h2 className="text-mute">{translate('login.Text.Title')}</h2>
            <p className="text-mute">{translate('login.Text.SubTitle')}</p>
            <div className="mt-10">
                <form onSubmit={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormGroup
                                label={translate('login.Label.Email')}
                                error={'email'}
                            >
                                <FormInput
                                    value={values.email}
                                    onChange={e => setField('email', e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <FormGroup
                                label={translate('login.Label.Password')}
                                error={'password'}
                            >
                                <FormPassword
                                    value={values.password}
                                    onChange={e => setField('password', e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col span={24}>
                            <Button block={true} type={'submit'} loading={loading}>
                                {translate('button.Login')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        </>
    );
}

export default LoginPage;
