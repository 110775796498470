import {createContext, useContext} from "react";

const DashboardContext = createContext();

export const DashboardProvider = ({children}) => {
    const values = {};

    return <DashboardContext.Provider value={values}>
        {children}
    </DashboardContext.Provider>
}

export const useDashboardContext = () => useContext(DashboardContext);
