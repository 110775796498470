const RoomApi = {
    postCreate: '/crud/rooms',
    postAction: '/crud/rooms/:id/action',

    putUpdate: '/crud/rooms/:id',

    deleteDestroy: '/crud/rooms/:id',

    getIndex: '/crud/rooms',
    getSelect: '/crud/rooms/select',
    getId: '/crud/rooms/:id',
}

export default RoomApi;