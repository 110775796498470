import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useMenuContext} from "context/MenuContext";
import MenuTable from "pages/menu/components/MenuTable";
import MenuFormModal from "pages/menu/components/MenuFormModal";
import MenuFilter from "pages/menu/components/MenuFilter";
import MenuWidgetModal from "pages/menu/components/MenuWidgetModal";

function MenuPage(props) {

    const {permission, modal, handleModal} = useMenuContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <MenuFilter/>
            <MenuTable/>
            { (can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <MenuFormModal/>}
                    {modal.widget && <MenuWidgetModal/>}
                </>
            )}
        </Page>
    );
}

export default MenuPage;
