import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useTestimonialContext} from "context/TestimonialContext";
import TestimonialTable from "pages/testimonial/components/TestimonialTable";
import TestimonialFormModal from "pages/testimonial/components/TestimonialFormModal";
import TestimonialFilter from "pages/testimonial/components/TestimonialFilter";

function TestimonialPage(props) {

    const {permission, modal, handleModal} = useTestimonialContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <TestimonialFilter/>
            <TestimonialTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <TestimonialFormModal/>}
                </>
            )}
        </Page>
    );
}

export default TestimonialPage;
