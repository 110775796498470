import {configureStore} from "@reduxjs/toolkit";

import appStore from './module/app.store'
import authStore from './module/auth.store'

export default configureStore({
    reducer: {
        appStore,
        authStore,
    }
})
