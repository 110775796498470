import {createContext, useContext, useState} from "react";
import {useTable} from "hooks/useTable";
import PermissionApi from "api/permission.api";
import {
    servicePermissionDestroy,
    servicePermissionOptionSave,
    servicePermissionSave,
    servicePermissionUpdateAction
} from "services/permission.service";

const PermissionContext = createContext();

export const PermissionProvider = ({children}) => {

    const translateKey = 'crm.Permission';
    const permission = 'permission';
    const [tableRow, setTableRow] = useState({});
    const [filter, setFilter] = useState({});
    const [modal, setModal] = useState({
        form: false,
        option: false
    });

    const {dataSource, loading: tableLoading, fetchDataSource, handleQuery, query} = useTable(PermissionApi.getIndex);

    const handleFilter = (newFilter) => {
        setFilter(f => ({
            ...f,
            ...newFilter
        }))
    }

    const handleModal = (name, action, data = {}) => {
        setModal(m => ({
            ...m,
            [name]: action
        }))
        setTableRow(data);
    }

    const handleAction = async (id, action = 'is_active') => {
        const res = await servicePermissionUpdateAction(id, action);
        if (res) await fetchDataSource();
    }

    const handleDelete = async (id) => {
        const res = await servicePermissionDestroy(id);
        if (res) await fetchDataSource();
    }

    const handleSave = async (form) => {
        const res = await servicePermissionSave(form)
        if (res) {
            await fetchDataSource();
            return true;
        }
        return false;
    }

    const handleSaveOption = async (form) => {
        const res = await servicePermissionOptionSave(form)
        return !!res;
    }

    const values = {
        translateKey,
        modal,
        permission,
        filter,
        tableRow,
        dataSource,
        query,
        tableLoading,
        fetchDataSource,
        handleQuery,
        handleModal,
        handleFilter,
        handleAction,
        handleDelete,
        handleSave,
        handleSaveOption
    };

    return <PermissionContext.Provider value={values}>
        {children}
    </PermissionContext.Provider>
}

export const usePermissionContext = () => useContext(PermissionContext);
