import React from 'react';
import SliderPage from "pages/slider/SliderPage";
import {SliderProvider} from "context/SliderContext";

function SliderPageProvider(props) {
    return (
        <SliderProvider>
            <SliderPage/>
        </SliderProvider>
    );
}

export default SliderPageProvider;
