import React from 'react';
import {Page} from "components/ui";
import {can, translate} from "utils/helpers";
import {useTeamContext} from "context/TeamContext";
import TeamTable from "pages/team/components/TeamTable";
import TeamFormModal from "pages/team/components/TeamFormModal";
import TeamFilter from "pages/team/components/TeamFilter";

function TeamPage(props) {

    const {permission, modal, handleModal} = useTeamContext();

    return (
        <Page
            action={
                <>
                    {can(permission + '.create') && (
                        <button className="btn btn--primary" onClick={() => handleModal('form', true)}>
                            {translate('button.Add')}
                        </button>
                    )}
                </>
            }
        >
            <TeamFilter/>
            <TeamTable/>
            {(can(permission + '.create') || can(permission + '.update')) && (
                <>
                    {modal.form && <TeamFormModal/>}
                </>
            )}
        </Page>
    );
}

export default TeamPage;
